import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps'
import { clsx } from 'clsx'
import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { Dealer } from '../../hooks/use-dealers'
import { CENTER_OF_NETHERLANDS } from '../../lib/google-maps'

const MARKER_OFFSET = 0.045

type Props = {
  dealers: Dealer[]
  name: string
}

export const DealerMap = ({ dealers, name }: Props) => {
  const classes = getClassNames('checkout').stepShipping.dealerChooser
  const translations = getTranslations('checkout').stepShipping.dealerChooser

  const { register, watch } = useFormContext()
  const input = register(name)
  const value = watch(name)

  const [selectedDealer, setSelectedDealer] = useState<Dealer>()

  const map = useMap()

  return (
    <Map
      defaultZoom={7}
      maxZoom={10}
      minZoom={7}
      defaultCenter={CENTER_OF_NETHERLANDS}
      mapId={'dealerMap'}
      disableDefaultUI
      zoomControl
    >
      {dealers.map((dealer) => {
        const isOpen = selectedDealer === dealer
        const onToggle = (isOpen: boolean) => setSelectedDealer(isOpen ? dealer : undefined)

        return (
          <Fragment key={dealer.id}>
            <AdvancedMarker
              onClick={() => {
                onToggle(!isOpen)
                map?.panTo({
                  lat: dealer.position.lat + MARKER_OFFSET,
                  lng: dealer.position.lng,
                })
                map?.setZoom(10)
              }}
              position={dealer.position}
              title={dealer.name}
            >
              <div className={clsx(classes.mapMarker, value === dealer.id && classes.selected)} />
            </AdvancedMarker>

            {isOpen && (
              // This is a infowindow made with the use of AdvancedMarker.
              // We use the AdvancedMarker to create a custom infowindow because the default infoWidow can not be styled.
              <AdvancedMarker
                // We need to set the onClick handler to enable pointer-events for the radio button and label.
                onClick={() => {}}
                position={{
                  lat: dealer.position.lat + MARKER_OFFSET,
                  lng: dealer.position.lng,
                }}
                className={classes.mapInfoWindow}
                zIndex={1}
              >
                <input
                  {...input}
                  id={`map-dealer-${dealer.id}`}
                  type="radio"
                  value={dealer.id}
                  checked={value === dealer.id}
                />
                <label htmlFor={`map-dealer-${dealer.id}`}>
                  <address>
                    <strong>{dealer.name}</strong>
                    <div>
                      {dealer.street} {dealer.houseNumber}
                      <br />
                      {dealer.postalCode}, {dealer.city}
                    </div>
                  </address>

                  <span aria-hidden>
                    {dealer.id === value ? translations.selected : translations.select}
                  </span>
                </label>
              </AdvancedMarker>
            )}
          </Fragment>
        )
      })}
    </Map>
  )
}
