'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'

const EstimatedShippingCostContext = createContext<{
  setEstimatedShippingCost: (cost: number | undefined) => void
  estimatedShippingCost?: number
}>({
  setEstimatedShippingCost: () => {},
})

type Props = PropsWithChildren

export const EstimatedShippingCostProvider = ({ children }: Props) => {
  const [estimatedShippingCost, setEstimatedShippingCost] = useState<number>()

  return (
    <EstimatedShippingCostContext.Provider
      value={{
        estimatedShippingCost,
        setEstimatedShippingCost,
      }}
    >
      {children}
    </EstimatedShippingCostContext.Provider>
  )
}

export const useEstimatedShippingCost = () => useContext(EstimatedShippingCostContext)
