import { useMap } from '@vis.gl/react-google-maps'
import { clsx } from 'clsx'
import { useEffect, useId, useState } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { Dealer } from '../../hooks/use-dealers'
import { useDebounced } from '../../hooks/use-debounce'
import { useCurrentOrder } from '../../hooks/use-order'
import { Position, getDistanceFromLatLonInKm } from '../../lib/geometry'
import { getSearchLocation } from '../../lib/google-maps'
import { DealerMap } from './DealerMap'

type Props = {
  dealers: Dealer[]
}
const name = 'methodId'

export const DealerChooser = ({ dealers }: Props) => {
  const classes = getClassNames('checkout').stepShipping.dealerChooser
  const translations = getTranslations('checkout').stepShipping.dealerChooser
  const { order } = useCurrentOrder()
  const [search, setSearch, debouncedSearch] = useDebounced(order?.billing_address?.zip_code || '')
  const [searchLocation, setSearchLocation] = useState<Position>()

  const [showMore, setShowMore] = useState(false)
  const [tab, setTab] = useState('list')
  const { register, watch, formState } = useFormContext()
  const input = register(name)
  const value = watch(name)
  const error = get(formState.errors, name) as { message: string } | undefined
  const id = useId()
  const errorId = `${id}:error`
  const map = useMap()

  useEffect(() => {
    if (!map) {
      return
    }

    // first try to find a dealer by name
    const dealerByNameMatch =
      debouncedSearch.length > 3
        ? dealers.find((d) => d.name.toLowerCase().includes(debouncedSearch.toLowerCase()))
        : undefined
    if (dealerByNameMatch) {
      setSearchLocation(dealerByNameMatch.position)
      map.setCenter(dealerByNameMatch.position)
    } else {
      // otherwise use the Google Places API to find a location
      getSearchLocation(map, debouncedSearch).then((geometry) => {
        if (geometry?.viewport) {
          map.fitBounds(geometry.viewport)
        }
        if (geometry?.location) {
          setSearchLocation({ lat: geometry.location.lat(), lng: geometry.location.lng() })
        }
      })
    }
  }, [map, debouncedSearch])

  const formClasses = getClassNames('checkout').form

  const sortedDealers = searchLocation ? [...dealers].sort(byDistanceTo(searchLocation)) : dealers

  return (
    <div className={classes.root}>
      <input
        type="search"
        placeholder="Search dealers"
        onChange={(e) => {
          setShowMore(false)
          setSearch(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        value={search}
      />

      <div role="tablist">
        <button
          role="tab"
          id="tab-list"
          aria-controls="tabpanel-list"
          onClick={() => setTab('list')}
          type="button"
          aria-selected={tab === 'list'}
        >
          {translations.list}
        </button>
        <button
          role="tab"
          id="tab-map"
          aria-controls="tabpanel-map"
          onClick={() => setTab('map')}
          type="button"
          aria-selected={tab === 'map'}
        >
          {translations.map}
        </button>
      </div>

      <div
        role="tabpanel"
        id="tabpanel-list"
        aria-labelledby="tab-list"
        className={clsx(tab === 'list' && classes.selected)}
      >
        <ol>
          {(showMore
            ? sortedDealers.slice(0, 10)
            : sortedDealers.sort(bySelectedFirst(value)).slice(0, 3)
          ).map((dealer) => (
            <li key={dealer.id}>
              <input
                {...input}
                id={`dealer-${dealer.id}`}
                type="radio"
                value={dealer.id}
                aria-errormessage={errorId}
              />
              <label htmlFor={`dealer-${dealer.id}`}>
                <address>
                  <strong>{dealer.name}</strong>
                  <br />
                  {dealer.street} {dealer.houseNumber}
                  <br />
                  {dealer.postalCode}, {dealer.city}
                </address>

                <span aria-hidden>
                  {dealer.id === value ? translations.selected : translations.select}
                </span>
              </label>
            </li>
          ))}
        </ol>

        {!showMore && sortedDealers.length > 3 && (
          <button onClick={() => setShowMore((s) => !s)} type="button">
            {translations.showMore}
          </button>
        )}
      </div>

      <div
        role="tabpanel"
        id="tabpanel-map"
        aria-labelledby="tab-map"
        className={clsx(tab === 'map' && classes.selected)}
      >
        <DealerMap dealers={sortedDealers} name={name} />
      </div>

      {error?.message && formState.submitCount > 0 && (
        <div id={errorId} className={formClasses.error} role="alert" aria-live="polite">
          {error.message}
        </div>
      )}

      {error?.message && formState.submitCount > 0 && (
        <div id={errorId} className={formClasses.error} role="alert" aria-live="polite">
          {error.message}
        </div>
      )}
    </div>
  )
}

const bySelectedFirst =
  (value: string) =>
  (a: Dealer, b: Dealer): number =>
    a.id === value ? -1 : b.id === value ? 1 : 0

const byDistanceTo =
  (location: Position) =>
  (a: Dealer, b: Dealer): number =>
    getDistanceFromLatLonInKm(location, a.position) -
    getDistanceFromLatLonInKm(location, b.position)
