export type Position = {
  lat: number
  lng: number
}

/**
 * Calculates the distance between two lat/lng positions in km.
 *
 * This function is based on the [Haversine formula](https://en.wikipedia.org/wiki/Haversine_formula).
 */
export const getDistanceFromLatLonInKm = (position1: Position, position2: Position) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(position2.lat - position1.lat) // deg2rad below
  const dLon = deg2rad(position2.lng - position1.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(position1.lat)) *
      Math.cos(deg2rad(position2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

const deg2rad = (deg: number) => deg * (Math.PI / 180)
