import { clsx } from 'clsx'
import { FormHTMLAttributes } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import { getClassNames } from '../../config'

type Translations = {
  submitting: string
  submit: string
}

type Props<T extends FieldValues> = {
  translations: Translations
  onSubmit?: (values: T) => any | Promise<any>
  form: UseFormReturn<T>
  serverError?: string
  disabled?: boolean
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'onChange' | 'onBlur' | 'id'>

export function Form<T extends FieldValues>({
  onSubmit,
  translations,
  form,
  serverError,
  disabled,
  children,
  ...attributes
}: Props<T>) {
  const classes = getClassNames('checkout').form

  return (
    <FormProvider {...form}>
      <form
        {...attributes}
        className={clsx(classes.root, attributes.className)}
        {...(onSubmit ? { onSubmit: form.handleSubmit(onSubmit) } : {})}
        aria-busy={form.formState.isSubmitting}
        noValidate
      >
        {children}

        <footer className={classes.actions}>
          {serverError && (
            <div className={classes.error} role="alert">
              {serverError}
            </div>
          )}
          {onSubmit && (
            <button
              type="submit"
              className={classes.submitButton}
              aria-busy={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting || disabled}
            >
              {form.formState.isSubmitting && <div className={classes.loading} />}
              {form.formState.isSubmitting ? translations.submitting : translations.submit}
            </button>
          )}
        </footer>
      </form>
    </FormProvider>
  )
}
