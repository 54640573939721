export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

const isObject = <T extends object>(obj: any): obj is T => obj && typeof obj === 'object'

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 */
export function mergeDeep<T>(obj1: T, obj2?: DeepPartial<T>): T {
  return [obj1, obj2].reduce((prev, obj) => {
    Object.keys(obj ?? {}).forEach((key) => {
      const pVal = prev[key]
      const oVal = (obj as any)[key]

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal)
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {} as any)
}

/**
 * Utility for lists to ensure there are no empty values.
 *
 * @example
 * ```ts
 * const products = [
 * {id: 'a', price: {centAmount: 20}},
 * {id: 'b', price: null},
 * {id: 'c', price: {centAmount: 30}}
 * ]
 * products.map(x => price).filter(isValue).map(p => p.centAmount)
 *
 * // results in
 * [20, 30]
 * ```
 */
export const isValue = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined

/**
 * Merges two arrays, creating a single array with tuples from both arrays.
 *
 * @example
 * ```ts
 * const a = [1,2,3]
 * const b = ['a', 'b', 'c']
 *
 * zip(a, b) // [[1, 'a'], [2, 'b'], [3, 'c']]
 * ```
 */
export const zip = <T>(a: T[], b: T[]) => a.map((k, i) => [k, b[i]] as const)

// Range
export function range(max: number): number[] {
  const diff = max - 1
  if (diff === 1) {
    return [1]
  }

  const keys = Array(Math.abs(diff) + 1).keys()
  return Array.from(keys).map((x) => {
    const increment = max > 1 ? x : -x
    return 1 + increment
  })
}

/**
 * Utility to filter out double items in an array by some measure.
 *
 * @example
 * ```ts
 * const products = [
 * {id: 'a', price: 1},
 * {id: 'a', price: 1},
 * {id: 'b', price: 1}
 * ]
 * products.filter(uniqueBy(p => p.id))
 *
 * // results in
 * [{id: 'a', price: 1}, {id: 'b', price: 1}]
 * ```
 */
export const uniqueBy =
  <T, S>(getValue: (item: T) => S) =>
  (v: T, i: number, s: T[]) =>
    s.findIndex((e) => getValue(e) === getValue(v)) === i

/**
 * Groups a list based on a callback.
 * Returns a Map where the keys are the result of the callback.
 *
 * @example
 * ```ts
 * groupBy(
 *   [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}, {age: 16, name: 'Jack'}],
 *   p => p.age,
 * )
 *
 * // results in
 * Map {
 *  16: [{age: 16, name: 'Jack'}],
 *  18: [{age: 18, name: 'John'}, {age: 18, name: 'Joe'}],
 * }
 * ```
 */
export const groupByMap = <T, S>(list: T[], keyGetter: (i: T) => S) => {
  const map = new Map<S, T[]>()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const intersection =
  <T, S>(byId: (item: T) => S) =>
  (listA: T[], listB: T[]) =>
    listA.filter((method) => listB.some((c) => byId(c) === byId(method)))
