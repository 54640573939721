'use client'

import type { HTMLAttributes } from 'react'
import React, { useId } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { getClassNames } from '../../config'

type Props = {
  name: string
  placeholder?: string
  type?: string
  label?: string
  autoComplete?: string
  min?: number
  max?: number
  step?: 'any' | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onBlur'>

export function InputField({
  label,
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  max,
  min,
  step,
  inputMode,
  autoComplete,
  ...attributes
}: Props) {
  const id = useId()
  const errorId = `${id}:error`
  const classes = getClassNames('checkout').form

  const { register, formState } = useFormContext()
  const error = get(formState.errors, name) as { message: string } | undefined
  const invalid = Boolean(error?.message && formState.submitCount > 0)
  const input = register(name, {
    onChange,
    onBlur,
    ...(type === 'number' ? { valueAsNumber: true } : type === 'date' ? { valueAsDate: true } : {}),
  })

  return (
    <div
      {...attributes}
      className={classes.control}
      data-name={name}
      aria-invalid={invalid}
      aria-labelledby={`${id}-label`}
    >
      <input
        {...input}
        inputMode={inputMode}
        type={type}
        id={id}
        placeholder={placeholder}
        max={max}
        min={min}
        step={step}
        aria-errormessage={errorId}
        aria-invalid={invalid}
        autoComplete={autoComplete}
        className={classes.input}
      />

      <label htmlFor={id} id={`${id}-label`} className={classes.label}>
        {label}
      </label>

      {invalid && (
        <div id={errorId} className={classes.error} role="alert" aria-live="polite">
          {error?.message}
        </div>
      )}
    </div>
  )
}
