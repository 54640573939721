import { Order } from '@commercelayer/sdk'
import { getClassNames, getTranslations } from '../config'
import Money from './Money'

export type Props = {
  order: Order
}

function LineItems({ order }: Props) {
  const classes = getClassNames('lineItems')
  const translations = getTranslations('lineItems')

  return (
    <ul className={classes.root}>
      {order.line_items
        ?.filter((l) => l.item_type === 'skus')
        .map((lineItem) => (
          <li key={lineItem.id}>
            <figure>
              <img
                src={lineItem.image_url ?? ''}
                width={80}
                height={60}
                alt={lineItem.name ?? ''}
              />
            </figure>

            <div>
              <div className={classes.header}>
                <div>
                  <strong className={classes.title}>{lineItem.name}</strong>

                  <div className={classes.quantity}>
                    <span>{translations.quantity}</span> <span>{lineItem.quantity}</span>
                  </div>
                </div>

                {lineItem.total_amount_cents && lineItem.discount_cents !== 0 ? (
                  <div className={classes.priceCompare}>
                    <Money
                      centAmount={lineItem.total_amount_cents}
                      currency={order.currency_code!}
                      className={classes.priceOld}
                    />

                    {lineItem.discount_cents && (
                      <Money
                        centAmount={lineItem.discount_cents + lineItem.total_amount_cents}
                        currency={order.currency_code!}
                        className={classes.price}
                      />
                    )}
                  </div>
                ) : (
                  lineItem.total_amount_cents && (
                    <Money
                      centAmount={lineItem.total_amount_cents}
                      currency={order.currency_code!}
                      className={classes.price}
                    />
                  )
                )}
              </div>
            </div>
          </li>
        ))}
    </ul>
  )
}

export default LineItems
