import { ObjectSchema, object, string } from 'yup'
import { CheckoutTranslations } from '../../config/types'
import { FormAddress, phonePattern } from '../../lib/address'

export const getAddressSchema = (translations: CheckoutTranslations): ObjectSchema<FormAddress> =>
  object({
    salutation: string()
      .default('')
      .required(translations.address.validate.required)
      .oneOf(['male', 'female'], translations.address.validate.required),
    firstName: string().required(translations.address.validate.required).default(''),
    lastNamePreposition: string().default('').label(translations.address.lastNamePreposition),
    lastName: string().required(translations.address.validate.required).default(''),
    zipCode: string()
      .required(translations.address.validate.required)
      .matches(/^\d{4} ?[a-z]{2}$/i, translations.address.validate.zipCode)
      .default(''),
    houseNumber: string()
      .required(translations.address.validate.required)
      .matches(/^[0-9]*$/, translations.address.validate.houseNumber)
      .default(''),
    houseNumberAddition: string().default('').label(translations.address.houseNumberAddition),
    street: string().required(translations.address.validate.required).default(''),
    city: string().required(translations.address.validate.required).default(''),
    phone: string()
      .required(translations.address.validate.required)
      .matches(phonePattern, translations.address.validate.phoneNumber)
      .default(''),
    company: string().default('').label(translations.address.company),
  })
