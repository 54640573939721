import { clsx } from 'clsx'
import { getClassNames, getTranslations } from '../config'
import { MoneyClassNames } from '../config/types'

type Props = {
  centAmount: number
  currency: string
  className?: string
}

function Money({ centAmount, currency, className }: Props) {
  const classes = getClassNames('money')
  const translations = getTranslations('money')

  const formatter = Intl.NumberFormat(window.commerceConfig.locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  })

  const usFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  })

  const parts = formatter.formatToParts(centAmount / 100)

  return (
    <span
      aria-label={translations.money}
      className={clsx(classes.root, centAmount === 0 && classes.free, className)}
      data-value={usFormatter.format(centAmount / 100)}
    >
      {centAmount === 0
        ? translations.free
        : parts.map((part) => (
            <span key={part.type} className={classes[part.type as keyof MoneyClassNames]}>
              {part.value}
            </span>
          ))}
    </span>
  )
}

export default Money
