import { mergeDeep } from '../lib/collection'
import { CL_ORDER_KEY, TOKEN_COOKIE } from './constants'
import { defaultClassNames } from './default-classnames'
import { ClassNames, CommerceConfig, Translations } from './types'

let defaultTranslations: Translations = {} as any

declare global {
  interface Window {
    commerceConfig: CommerceConfig
  }
}

export async function getDefaultTranslations(locale?: string) {
  switch (locale) {
    case 'nl-NL':
      return import('../translations/nl-NL').then((r) => r.default)
    default:
      return import('../translations/en-US').then((r) => r.default)
  }
}

export async function registerDefaultTranslations(translations: Translations) {
  defaultTranslations = mergeDeep(translations, window.commerceConfig.translations)
}

export const getTranslations = <K extends keyof Translations>(widgetName: K): Translations[K] =>
  defaultTranslations[widgetName]

export const getCommerceLayer = () => window.commerceConfig.commerceLayer

export const getOrderKey = () => {
  const scope = getCommerceLayer().scope.replace(/:/g, '-')
  return `${CL_ORDER_KEY}-${scope}`
}

export const getTokenCookie = () => {
  const scope = getCommerceLayer().scope.replace(/:/g, '-')
  return `${TOKEN_COOKIE}-${scope}`
}

export const getClassNames = <K extends keyof ClassNames>(widgetName: K): ClassNames[K] =>
  mergeDeep(defaultClassNames[widgetName], window.commerceConfig.classNames?.[widgetName])
