import { Address } from '@commercelayer/sdk'

export const AddressSummary = ({ address, title }: { address: Address; title: string }) => (
  <div>
    <h3>{title}</h3>
    <address>
      {address.company && (
        <>
          {address.company}
          <br />
        </>
      )}
      {address.full_name && (
        <>
          {address.first_name} {address?.metadata?.lastNamePreposition} {address.last_name}
          <br />
        </>
      )}
      {address.line_1} {address.line_2}
      <br />
      {address.zip_code}, {address.city}
      <br />
      {address.phone}
    </address>
  </div>
)
