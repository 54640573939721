import { kebabCase } from 'change-case'
import { UseFormReturn } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { CheckoutClassNames } from '../../config/types'
import { capitalize, fetchAddress, normalizedPhoneNumber, sanitizeZipCode } from '../../lib/address'
import { InputField } from '../form/InputField'
import { RadioGroupField } from '../form/RadioGroupField'

type Props = {
  addressType: 'billingAddress' | 'shippingAddress'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>
}

export const AddressFields = ({ addressType, form }: Props) => {
  const classes = getClassNames('checkout').form
  const translations = getTranslations('checkout').address

  return (
    <div id={kebabCase(addressType)} className={classes.controls}>
      <RadioGroupField
        label={translations.salutation}
        name={`${addressType}.salutation`}
        autoComplete="sex"
        required
        options={[
          { label: translations.male, value: 'male' },
          { label: translations.female, value: 'female' },
        ]}
      />

      <InputField
        label={translations.firstName}
        name={`${addressType}.firstName`}
        type="text"
        autoComplete="first-name"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.firstName`, capitalize(e.target.value))}
      />
      <InputField
        label={translations.lastNamePreposition}
        name={`${addressType}.lastNamePreposition`}
        type="text"
      />
      <InputField
        label={translations.lastName}
        name={`${addressType}.lastName`}
        type="text"
        autoComplete="last-name"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.lastName`, capitalize(e.target.value))}
      />
      <InputField
        label={translations.zipCode}
        name={`${addressType}.zipCode`}
        type="text"
        autoComplete="postal-code"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.zipCode`, sanitizeZipCode(e.target.value))}
        onChange={async (e) => {
          const valid = await form.trigger([`${addressType}.zipCode`, `${addressType}.houseNumber`])
          if (!valid) {
            return
          }

          const zipCode = e.target.value
          const houseNumber = form.getValues()[addressType]!.houseNumber

          void setStreetAndCity(addressType, form, classes, zipCode, houseNumber)
        }}
      />
      <InputField
        label={translations.houseNumber}
        name={`${addressType}.houseNumber`}
        data-required
        inputMode="numeric" // sets a mobile numeric keyboard, but doesn't trigger the type="number" buttons on desktop
        onChange={async (e) => {
          const valid = await form.trigger([`${addressType}.zipCode`, `${addressType}.houseNumber`])
          if (!valid) {
            return
          }

          const zipCode = form.getValues()[addressType]!.zipCode
          const houseNumber = e.target.value

          void setStreetAndCity(addressType, form, classes, zipCode, houseNumber)
        }}
      />
      <InputField
        label={translations.houseNumberAddition}
        name={`${addressType}.houseNumberAddition`}
        type="text"
      />
      <InputField
        label={translations.street}
        name={`${addressType}.street`}
        type="text"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.street`, capitalize(e.target.value))}
      />
      <InputField
        label={translations.city}
        name={`${addressType}.city`}
        type="text"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.city`, capitalize(e.target.value))}
      />
      <InputField
        label={translations.phone}
        name={`${addressType}.phone`}
        type="tel"
        autoComplete="tel"
        data-required
        onBlur={(e) => form.setValue(`${addressType}.phone`, normalizedPhoneNumber(e.target.value))}
      />
      <InputField
        label={translations.company}
        name={`${addressType}.company`}
        type="text"
        autoComplete="organization"
      />
    </div>
  )
}

const setStreetAndCity = (
  addressType: string,
  form: UseFormReturn,
  classes: CheckoutClassNames['form'],
  zipCode: string,
  houseNumber: string
) => {
  document.getElementById(kebabCase(addressType))?.classList.remove(classes.apiValidated)

  const addressApi = window.commerceConfig.addressApi
  if (!addressApi) return

  fetchAddress(addressApi.baseUrl, addressApi.apiKey, zipCode, houseNumber)
    .then((data) => {
      if (data.status !== 'ok') {
        return
      }
      const streetValue = data.results[0].street
      const cityValue = data.results[0].city

      form.setValue(`${addressType}.street`, streetValue, { shouldValidate: true })
      form.setValue(`${addressType}.city`, cityValue, { shouldValidate: true })

      document.getElementById(kebabCase(addressType))?.classList.add(classes.apiValidated)
    })
    .catch()
}
