import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import PortalComponents from './PortalComponents'
import { CommerceProvider } from './components/CommerceProvider'
import { getDefaultTranslations, registerDefaultTranslations } from './config'
import './sentry'
import AddToCartButton from './widgets/AddToCartButtonWidget'
import Availability from './widgets/AvailabilityWidget'
import CartCounter from './widgets/CartCounterWidget'
import CartQuickView from './widgets/CartQuickViewWidget'
import Cart from './widgets/CartWidget'
import CheckoutComplete from './widgets/CheckoutCompleteWidget'
import Checkout from './widgets/CheckoutWidget'
import Price from './widgets/PriceWidget'

const components = {
  AddToCartButton,
  Availability,
  Cart,
  CartCounter,
  CartQuickView,
  Checkout,
  CheckoutComplete,
  Price,
}

async function main() {
  // Set translations based on global config
  const locale = window.commerceConfig?.locale
  const translations = await getDefaultTranslations(locale)
  registerDefaultTranslations(translations)

  // Render Portals
  const container = document.createElement('div')
  document.body.append(container)

  createRoot(container).render(
    <StrictMode>
      <CommerceProvider>
        <PortalComponents components={components} componentSelector="widget" />
      </CommerceProvider>
    </StrictMode>
  )
}
main()
