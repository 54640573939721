'use client'

import { clsx } from 'clsx'
import type { HTMLAttributes } from 'react'
import { useId } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { getClassNames } from '../../config'

type Props = {
  name: string
  label: string
} & HTMLAttributes<HTMLDivElement>

export function ToggleField({ label, name, ...attributes }: Props) {
  const classes = getClassNames('checkout').form
  const id = useId()
  const errorId = `${id}:error`

  const { register, formState } = useFormContext()
  const error = get(formState.errors, name) as { message: string } | undefined
  const invalid = Boolean(error?.message && formState.submitCount > 0)
  const input = register(name)

  return (
    <div
      {...attributes}
      aria-invalid={invalid}
      className={clsx(classes.control, attributes.className)}
      data-name={name}
      aria-labelledby={`${id}-label`}
      aria-errormessage={errorId}
    >
      <label htmlFor={id} id={`${id}-label`} className={classes.switch}>
        <input
          id={id}
          type="checkbox"
          {...input}
          className={clsx(classes.switchInput)}
          aria-invalid={Boolean(error)}
          role="switch"
        />
        {label}
      </label>

      {invalid && (
        <div id={errorId} className={classes.error} role="alert" aria-live="polite">
          {error?.message}
        </div>
      )}
    </div>
  )
}
