import { useEffect, useState } from 'react'

export const useDebounced = (initialValue = '', ms = 300) => {
  const [state, setState] = useState(initialValue)
  const [debouncedState, setDebouncedInputValue] = useState(initialValue)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(state)
    }, ms)
    return () => clearTimeout(timeoutId)
  }, [state])
  return [state, setState, debouncedState] as const
}
