import AdyenCheckout from '@adyen/adyen-web'
import Dropin from '@adyen/adyen-web/dist/types/components/Dropin'
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types'
import { Order } from '@commercelayer/sdk'
import { useEffect, useRef } from 'react'

type Props = {
  clientKey?: string
  order?: Order
  paymentMethods?: PaymentMethod[]
  locale: string
  onAdditionalDetails?: (values: any, component: Dropin) => void | Promise<void>
  onSubmit?: (values: any, component: Dropin) => void | Promise<void>
  onReady?: (component: Dropin) => void | Promise<void>
  onChange?: (state: AdyenState, component: Dropin) => void
  onSelect?: (state: AdyenState, component: Dropin) => void
}

type AdyenState = {
  data: any
  errors: any
  isValid: boolean
  valid: any
}

export const useAdyen = ({
  clientKey,
  order,
  paymentMethods,
  locale,
  onReady,
  onSubmit,
  onAdditionalDetails,
  onChange,
  onSelect,
}: Props) => {
  const paymentContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = paymentContainer.current

    if (!(element && order && paymentMethods)) {
      return
    }

    AdyenCheckout({
      locale,
      environment: clientKey?.startsWith('test') ? 'test' : 'live',
      clientKey,
      amount: {
        currency: order.currency_code ?? 'EUR',
        value: order.total_amount_with_taxes_cents ?? 0,
      },
      countryCode: order.country_code ?? 'nl',
      paymentMethodsResponse: {
        paymentMethods,
      },
      paymentMethodsConfiguration: {
        ideal: {
          showPayButton: false,
        },
        card: {
          hasHolderName: true,
          showPayButton: false,
        },
      },
      onSelect,
      onChange,
      onAdditionalDetails,
      onSubmit,
      openFirstPaymentMethod: false,
    }).then((checkout) => {
      const dropIn = checkout
        .create('dropin', {
          openFirstPaymentMethod: false,
          onSelect,
          onAdditionalDetails,
        })
        .mount(element)

      return onReady?.(dropIn as any)
    })
  }, [paymentContainer.current, clientKey, paymentMethods, Boolean(order)])

  return {
    paymentContainer,
  }
}

export type ResultCode =
  | 'AuthenticationFinished'
  | 'AuthenticationNotRequired'
  | 'Authorised'
  | 'Cancelled'
  | 'ChallengeShopper'
  | 'Error'
  | 'IdentifyShopper'
  | 'Pending'
  | 'PresentToShopper'
  | 'Received'
  | 'RedirectShopper'
  | 'Refused'
