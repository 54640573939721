import { HTMLAttributes, ReactNode } from 'react'
import useCollapse from 'react-collapsed'
import { getClassNames, getTranslations } from '../../config'

type Props = {
  current: number
  value: number
  onChangeStep: (value: number) => void
  getToggleChildren: (isExpanded: boolean) => ReactNode
} & HTMLAttributes<HTMLDivElement>

export const Step = ({
  onChangeStep,
  getToggleChildren,
  children,
  current,
  value,
  ...attributes
}: Props) => {
  const classes = getClassNames('checkout').step
  const translations = getTranslations('checkout').overview

  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: current === value,
    onCollapseStart: () => {
      document
        .getElementById(getToggleProps().id)
        ?.scrollIntoView({ block: 'nearest', behavior: 'auto' })
    },
  })

  return (
    <div {...attributes} className={classes.root}>
      <button {...getToggleProps()} onClick={() => onChangeStep(value)} disabled={current < value}>
        {getToggleChildren(isExpanded)}
        {!isExpanded && current > value && <div className={classes.edit}>{translations.edit}</div>}
      </button>
      <div {...getCollapseProps()}>{isExpanded && children}</div>
    </div>
  )
}
