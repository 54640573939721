import { useEffect, useState } from 'react'
import { getClassNames } from '../config'
import { useCurrentOrder } from '../hooks/use-order'
import { sum } from '../lib/math'

function CartCounterWidget() {
  const classes = getClassNames('cartCounter')

  const { order } = useCurrentOrder()
  const [, setUpdateOrder] = useState(false)

  useEffect(() => {
    const updateQuantity = () => {
      setUpdateOrder((x) => !x)
    }

    window.addEventListener('addToCart', updateQuantity)

    return () => {
      window.removeEventListener('addToCart', updateQuantity)
    }
  }, [])

  const lineItems =
    order?.line_items?.filter(
      (l) => l.item_type === 'skus' && l?.total_amount_cents && l.total_amount_cents >= 0
    ) ?? []

  const quantity = sum(lineItems, (lineItem) => lineItem.quantity ?? 0)

  if (!quantity) {
    return null
  }

  return (
    <span className={classes.root} title="cart amount">
      {quantity}
    </span>
  )
}

export default CartCounterWidget
