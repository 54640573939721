import { useEffect, useState } from 'react'
import LineItems from '../components/LineItems'
import { getClassNames, getTranslations } from '../config'
import { useCurrentOrder } from '../hooks/use-order'

type Props = {
  defaultOpen?: boolean
}

function CartQuickViewWidget({ defaultOpen = false }: Props) {
  const classes = getClassNames('cartQuickView')
  const translations = getTranslations('cartQuickView')
  const checkoutURL = window.commerceConfig?.urls.cart

  const checkoutCompleteURL = window.location.pathname + window.location.search

  const [open, setOpen] = useState(defaultOpen)
  const { order } = useCurrentOrder()

  useEffect(() => {
    if (checkoutCompleteURL === checkoutURL) return

    const openDialog = () => {
      setOpen(true)
    }

    window.addEventListener('addToCart', openDialog)

    return () => {
      window.removeEventListener('addToCart', openDialog)
    }
  }, [])

  if (!order) {
    return null
  }

  return (
    <>
      <dialog className={classes.root} open={open}>
        <div>
          <div className={classes.scroll}>
            <div className={classes.notice}>{translations.notice}</div>

            <LineItems order={order} />
          </div>

          <div className={classes.actions}>
            <button
              className={classes.proceedShoppingButton}
              type="button"
              onClick={() => setOpen(false)}
            >
              {translations.proceedShopping}
            </button>

            <a className={classes.proceedCartButton} href={checkoutURL}>
              {translations.proceedCart}
            </a>
          </div>
        </div>
        <div className={classes.backDrop}></div>
      </dialog>
    </>
  )
}

export default CartQuickViewWidget
