import { getClassNames } from '../config'

export const Loader = () => {
  const classes = getClassNames('loader')

  return (
    <div className={classes.root}>
      <span>Loading...</span>
    </div>
  )
}
