import { clsx } from 'clsx'
import { useState } from 'react'
import { getClassNames, getTranslations } from '../config'
import { useCurrentOrder } from '../hooks/use-order'
import { useProduct } from '../hooks/use-product'
import { addToCartEvent, fireEvent } from '../lib/analytics'

export type Props = {
  sku: string
  option?: string
}

function AddToCartButtonWidget({ sku, option }: Props) {
  const classes = getClassNames('addToCartButton')
  const translations = getTranslations('addToCartButton')

  const [maxQuantity, setMaxQuantity] = useState(false)
  const [loading, setLoading] = useState(false)
  const [added, setAdded] = useState(false)

  const { product, addToCart, mutate: productMutate } = useProduct(sku, option)
  const { order, mutate: orderMutate } = useCurrentOrder()

  const currentQuantity =
    order?.line_items?.find((line_item) => line_item.sku_code === sku)?.quantity ?? 0
  const hasStock = product?.stock_items?.some((i) => (i.quantity ?? 0) - currentQuantity > 0)

  return (
    <>
      <button
        className={clsx(classes.root, added ? classes.added : undefined)}
        aria-busy={loading}
        disabled={!hasStock || added || maxQuantity || loading}
        onClick={async () => {
          // 1. check for max quantity in cart
          if (currentQuantity >= 10) {
            setMaxQuantity(true)

            return
          }

          // 2. set loading state
          setLoading(true)

          // 3. do add-to-cart api call to commerce layer
          return addToCart(1)
            .then(async () => {
              // 4. set added button state
              setAdded(true)

              // 5. update product and order query cache data
              await Promise.all([productMutate(), orderMutate()])

              // 6. fire analytics event
              fireEvent(addToCartEvent(product!))

              // 7. fire custom event to trigger quick cart anc cart counter
              window.dispatchEvent(new CustomEvent('addToCart'))

              // 8. revert state back to normal after 3 seconds
              window.setTimeout(() => setAdded(false), 3000)
            })
            .finally(() => setLoading(false))
        }}
      >
        <span className={classes.icon}></span>
        <span>{added ? translations.added : translations.addToCart}</span>
      </button>

      {maxQuantity && hasStock && (
        <p className={classes.stockMessage} role="alert" aria-live="polite">
          {translations.maxQuantityMessage}
        </p>
      )}

      {!hasStock && currentQuantity > 0 && (
        <p className={classes.stockMessage} role="alert" aria-live="polite">
          {translations.maxStockMessage}
        </p>
      )}
    </>
  )
}

export default AddToCartButtonWidget
