import { ShippingCategory } from '@commercelayer/sdk'
import { clsx } from 'clsx'
import Markdown from 'react-markdown'
import { getClassNames, getTranslations } from '../config'
import { AvailabilityTranslations } from '../config/types'
import { useProduct } from '../hooks/use-product'

export type Props = {
  sku: string
  translations?: AvailabilityTranslations
}

function AvailabilityWidget({ sku, translations = getTranslations('availability') }: Props) {
  const classes = getClassNames('availability')

  const { product } = useProduct(sku)

  const hasStock = product?.stock_items?.some((i) => i.quantity)

  return (
    <small className={clsx(classes.root, hasStock ? classes.hasStock : classes.outOfStock)}>
      <span className={classes.stock}>
        <Markdown>
          {hasStock
            ? getDeliveryMessage(translations, product?.shipping_category ?? undefined)
            : translations.outOfStock}
        </Markdown>
      </span>
    </small>
  )
}

export default AvailabilityWidget

const getDeliveryMessage = (
  translations: AvailabilityTranslations,
  shippingCategory?: ShippingCategory
): string => {
  if (shippingCategory?.id === window.commerceConfig.shippingCategories.dealerRequiredItems) {
    return translations.dealerRequiredDeliveryMessage
  }
  return translations.deliveryMessage
}
