import { Address, Order } from '@commercelayer/sdk'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ObjectSchema, object, string } from 'yup'
import { getTranslations } from '../../config'
import { CheckoutTranslations } from '../../config/types'
import { useCurrentOrder } from '../../hooks/use-order'
import { FormAddress, emailPattern, formAddressToApiAddress } from '../../lib/address'
import { Form } from '../form/Form'
import { InputField } from '../form/InputField'
import { AddressFields } from './AddressFields'
import { getAddressSchema } from './schema'

type Props = {
  onSubmit: () => void
}

export const CheckoutAddresses = ({ onSubmit }: Props) => {
  const translations = getTranslations('checkout')
  const { order, updateOrder } = useCurrentOrder()

  const schema = useMemo(() => getSchema(translations), [translations])

  const initialValues = useMemo(
    () => ({ ...schema.cast({}), ...(order ? apiOrderToFormOrder(order) : undefined) }),
    [Boolean(order)]
  )

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  return (
    <Form
      form={form}
      translations={translations.stepBilling}
      onSubmit={async (values) => {
        if (!order) return

        await updateOrder(
          {
            id: order.id,
            customer_email: values.email,
            _shipping_address_same_as_billing: true,
          },
          {
            billingAddressUpdate: formAddressToApiAddress(values.billingAddress),
            shippingAddressUpdate: formAddressToApiAddress(values.billingAddress),
          }
        )
        onSubmit()
      }}
    >
      <InputField
        label={translations.stepBilling.email}
        name="email"
        type="email"
        autoComplete="email"
        data-required
      />
      <AddressFields addressType="billingAddress" form={form} />
    </Form>
  )
}

type FormValues = {
  email: string
  billingAddress: FormAddress
}

const getSchema = (translations: CheckoutTranslations): ObjectSchema<FormValues> =>
  object({
    email: string()
      .required(translations.address.validate.required)
      .matches(emailPattern, translations.address.validate.email)
      .default(''),
    billingAddress: getAddressSchema(translations).required(),
  })

const apiAddressToFormAddress = (address?: Address): FormAddress => ({
  city: address?.city ?? '',
  salutation: address?.metadata?.salutation ?? '',
  firstName: address?.first_name ?? '',
  lastName: address?.last_name ?? '',
  lastNamePreposition: address?.metadata?.lastNamePreposition ?? '',
  phone: address?.phone ?? '',
  street: address?.metadata?.street ?? '',
  houseNumber: address?.metadata?.houseNumber ?? '',
  houseNumberAddition: address?.metadata?.houseNumberAddition ?? '',
  zipCode: address?.zip_code ?? '',
  company: address?.company ?? '',
})

const apiOrderToFormOrder = (order: Order): FormValues => ({
  billingAddress: apiAddressToFormAddress(order.billing_address ?? undefined),
  email: order.customer_email ?? '',
})
