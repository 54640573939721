import { Address } from '@commercelayer/sdk'
import useSWR from 'swr'
import { getTokenFromCookie } from '../lib/auth'
import { uniqueBy } from '../lib/collection'
import { Position } from '../lib/geometry'

export type OpeningHours = {
  day: string
  from: string
  to: string
}

export type Dealer = {
  id: string
  position: Position
  name: string
  city: string
  country: string
  houseNumber: string
  postalCode: string
  street: string
  openingHours: OpeningHours[]
}

export const useDealers = (orderId?: string) => {
  const token = getTokenFromCookie()?.access_token
  const { data: dealers, ...rest } = useSWR(
    token && orderId ? { key: 'dealers', orderId, token } : undefined,
    ({ orderId, token }) => fetchDealers(orderId, token),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  return { dealers, ...rest }
}

const fetchDealers = async (orderId: string, token: string) => {
  if (!window.commerceConfig.dealerApi?.baseUrl) {
    throw new Error('Dealer API not configured')
  }

  return fetch(`${window.commerceConfig.dealerApi.baseUrl}/logistics_api/insert_shipment_proxy/`, {
    method: 'POST',
    body: JSON.stringify({ orderId, location: '1111AA' }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then<DealerResponse>((res) => res.json())
    .then((res) =>
      res.pickups
        .filter((p) => p.options?.length)
        .flatMap((p) => p.options)
        .filter(uniqueBy((o) => o.name))
        .map<Dealer>((o) => ({
          id: o.methodId,
          position: { lat: o.address.latitude, lng: o.address.longitude },
          name: o.name,
          city: o.address.city,
          country: o.address.country,
          houseNumber: o.address.houseNumber,
          postalCode: o.address.postalCode,
          street: o.address.street,
          openingHours: o.openingHours,
        }))
    )
}

export const orderMetadataToClDealerAddress = (metadata: Record<string, any>): Address => ({
  city: metadata.dealer_city,
  country_code: metadata.dealer_country,
  company: metadata.dealer_name,
  line_1: metadata.dealer_street,
  line_2: metadata.dealer_house_number,
  phone: '',
  zip_code: metadata.dealer_postal_code,
  state_code: '',
  created_at: '',
  id: '',
  updated_at: '',
  type: 'addresses',
})

type DealerResponse = {
  pickups: {
    date: string
    options: {
      name: string
      methodId: string
      address: {
        city: string
        country: string // country code
        houseNumber: string // e.g. 49-50
        latitude: number
        longitude: number
        postalCode: string
        street: string
      }
      openingHours: {
        day: string
        from: string
        to: string
      }[]
    }[]
  }[]
}
