import { getClassNames } from '../config'

function CartSkeleton() {
  const classes = getClassNames('cartSkeleton')

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.item}>
          <img alt="" />
          <div>
            <div className={classes.text}></div>
            <div>
              <div className={classes.select}></div>
              <div className={classes.text}></div>
            </div>
            <div className={classes.text}></div>
          </div>
        </div>
        <div className={classes.item}>
          <img alt="" />
          <div>
            <div className={classes.text}></div>
            <div>
              <div className={classes.select}></div>
              <div className={classes.text}></div>
            </div>
            <div className={classes.text}></div>
          </div>
        </div>
      </div>

      <div className={classes.overview}>
        <div>
          <div className={classes.text}></div>
          <div className={classes.text}></div>
        </div>
        <div className={classes.text}></div>
        <div>
          <div className={classes.text}></div>
          <div className={classes.text}></div>
        </div>
        <div className={classes.text}></div>
        <div className={classes.text}></div>
        <div className={classes.button}></div>
      </div>
    </div>
  )
}

export default CartSkeleton
