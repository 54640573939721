import { useEffect, useState } from 'react'

const DEFAULT_OPTIONS = { attributes: true, childList: true, subtree: true }

function useMutationObservable(
  targetEl: HTMLElement,
  callback: MutationCallback,
  options: MutationObserverInit = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState<MutationObserver>()

  useEffect(() => {
    const obs = new MutationObserver(callback)
    setObserver(obs)
  }, [callback, options, setObserver])

  useEffect(() => {
    if (!observer) return

    observer.observe(targetEl, options)

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [observer, targetEl, options])
}

export default useMutationObservable
