import { CommerceLayerClient } from '@commercelayer/sdk'
import { useCallback, useEffect } from 'react'
import { useClient } from '../hooks/use-commerce-layer'
import useMutationObservable from '../hooks/use-mutation-observer'
import { fireEvent, viewItemEvent, viewItemListEvent } from '../lib/analytics'
import { groupByMap, isValue, uniqueBy } from '../lib/collection'

export const ViewEvents = () => {
  const client = useClient()

  const onListMutation = useCallback((mutations: MutationRecord[]) => {
    const hasNewSkus = mutations.some((m) =>
      [...m.addedNodes].some((n) => n instanceof HTMLElement && n.getAttribute('widget') === 'sku')
    )

    if (!hasNewSkus) {
      return
    }

    sendEvents(client)
  }, [])

  useMutationObservable(document.body, onListMutation)

  useEffect(() => {
    sendEvents(client)
  }, [])

  return null
}

const sendEvents = (client: CommerceLayerClient) => {
  const skusWithListName = [...document.querySelectorAll('[widget="Price"][sku][view-event]')]
    .map((element) => ({
      sku: element.getAttribute('sku')!,
      list: element.getAttribute('view-event')!,
    }))
    .filter(uniqueBy(({ sku }) => sku))

  if (!skusWithListName.length) {
    return
  }

  client.skus
    .list({
      filters: {
        code_in: skusWithListName.map((s) => s.sku).join(','),
      },
      include: ['prices'],
    })
    .then((result) => {
      for (const [listName, group] of groupByMap(skusWithListName, (s) => s.list).entries()) {
        const products = group
          .map((s) => result.find((item) => item.code === s.sku))
          .filter(isValue)

        if (listName) {
          fireEvent(viewItemListEvent(products, listName))
        } else {
          for (const product of products) {
            fireEvent(viewItemEvent(product))
          }
        }
      }
    })
}
