import { Order } from '@commercelayer/sdk'
import { HTMLAttributes } from 'react'
import { getTranslations } from '../config'
import Money from './Money'

type Props = {
  order: Order
  /**
   * This value will overrule the existing shipping cost.
   * It Can be used to preview shipping cost before a shipping method is saved.
   */
  estimatedShippingCost?: number
} & HTMLAttributes<HTMLTableElement>

export const PriceTable = ({ order, estimatedShippingCost, ...attributes }: Props) => {
  const translations = getTranslations('priceTable')

  const currency = order.currency_code!

  const shippingCosts =
    // if we have an estimated shipping cost, we show that as a preview
    estimatedShippingCost ??
    // otherwise, we show the current shipping costs from the order
    order.shipping_amount_cents ??
    // as a fallback, we show the first available shipping costs
    order.shipments?.[0]?.available_shipping_methods?.[0]?.price_amount_cents ??
    0

  const subTotalCents = order.subtotal_amount_cents ?? 0
  let totalCents = 0
  if (order.total_amount_cents){
    totalCents = order.total_amount_cents
    if (!order.shipping_amount_cents){
      totalCents = totalCents + shippingCosts
    }
  }

  return (
    <table {...attributes}>
      <tbody>
        <tr>
          <th>{translations.subTotal}</th>
          <td>
            <Money centAmount={subTotalCents} currency={currency} />
          </td>
        </tr>

        {order.discount_amount_cents !== 0 && (
          <tr>
            <th>{translations.discount}</th>
            <td>
              {order.discount_amount_cents && (
                <Money centAmount={order.discount_amount_cents} currency={currency} />
              )}
            </td>
          </tr>
        )}

        {order.shipments?.[0]?.available_shipping_methods && (
          <tr>
            <th>{translations.shipmentCosts}</th>
            <td>
              <Money centAmount={shippingCosts} currency={currency} />
            </td>
          </tr>
        )}
      </tbody>

      <tfoot>
        <tr>
          <th>
            {translations.total} <small>{translations.taxInformation}</small>
          </th>
          <td>
            <Money centAmount={totalCents} currency={currency} />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
