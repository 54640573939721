type Props = {
  messageTemplate: string
  url: string
  className?: string
}

export const LinkMessage = ({ messageTemplate: template, url, className }: Props) => {
  const { first, link, last } = template.match(/(?<first>.*)\[(?<link>.*)\](?<last>.*)/)
    ?.groups ?? { first: undefined, link: undefined, last: undefined }
  return (
    <p className={className}>
      {first ?? ''}
      {link && (
        <a href={url} target="_blank" rel="noreferrer">
          {link}
        </a>
      )}
      {last ?? ''}
    </p>
  )
}
