import Cookies from 'js-cookie'
import { getTokenCookie } from '../config'
import { CommerceLayerConfig } from '../config/types'

export const fetchToken = ({ clientId, scope }: CommerceLayerConfig, options?: RequestInit) =>
  fetch('https://auth.commercelayer.io/oauth/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'client_credentials',
      client_id: clientId,
      scope,
    }),
    ...options,
  }).then<Token>((r) => r.json())

export const parseTokenCookie = (tokenCookie?: string) =>
  tokenCookie ? (JSON.parse(tokenCookie) as Token) : undefined

export const getTokenFromCookie = () => parseTokenCookie(Cookies.get(getTokenCookie()))

export const storeToken = (token: Token) => {
  setExpires(token)
  Cookies.set(getTokenCookie(), JSON.stringify(token))
  return token
}

function setExpires(token: Token) {
  // The age of an access token is 2 hours.
  // We subtract 5 seconds from the expiration date to smoothen network delays.
  token.expires_at = Date.now() + (Number(token.expires_in) - 5) * 1000
  return token
}

export type Token = {
  access_token: string
  token_type: string
  /** duration in seconds */
  expires_in: number
  scope: string
  /** timestamp in seconds */
  created_at: number
  /** timestamp in milliseconds */
  expires_at: number
}
