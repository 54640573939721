import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,

  // Alternatively, use `import.meta.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: import.meta.env.VITE_RELEASE,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
})
